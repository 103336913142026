import {AdapterAPI} from '../../api/AdapterAPI';
import {Analytics} from '../../core/Analytics';
import {Event} from '../../enums/Event';
import {AnalyticsConfig} from '../../types/AnalyticsConfig';
import {AnalyticsStateMachineOptions} from '../../types/AnalyticsStateMachineOptions';
import {logger} from '../../utils/Logger';
import {Adapter} from '../Adapter';

import {ShakaInternalAdapter} from './ShakaInternalAdapter';

export class ShakaAdapter extends Adapter implements AdapterAPI {
  constructor(config: AnalyticsConfig, player: any, opts?: AnalyticsStateMachineOptions) {
    super();
    if (this.hasPlayerAlreadyBeenAugmented(player)) {
      logger.errorMessageToUser('Bitmovin Analytics is already hooked up to this player instance');
      return;
    }
    this.markPlayerInstanceAsAugmented(player);
    this.internalAdapter = new ShakaInternalAdapter(player, opts);
    this.analytics = Analytics.create(config, this.internalAdapter);
    this.wrapPlayerLoad(player);
  }

  // The Shaka player's `load()` method does not trigger error events for exceptions thrown while loading the manifest.
  // Therefore, we wrap this method and catch exceptions and forward them to analytics.
  // TODO [AN-4149]: this needs to be reevaluated, since the cypress test with a none existing manifest is not working
  private wrapPlayerLoad(player: any) {
    const originalLoad = player.load;

    player.load = (...args) =>
      new Promise((resolve, reject) => {
        originalLoad
          .apply(player, args)
          .then((result) => resolve(result))
          .catch((error) => {
            const eventObject = {
              currentTime: 0,
              // See https://github.com/shaka-project/shaka-player/blob/main/lib/util/error.js
              code: error?.code ?? undefined,
              message: JSON.stringify({category: error?.category, severity: error?.severity}),
              data: {additionalData: JSON.stringify(error?.data)},
            };

            (this.internalAdapter as ShakaInternalAdapter).eventCallback(Event.ERROR, eventObject);

            reject(error);
          });
      });
  }
}
