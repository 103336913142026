import {AdAnalyticsSample} from '../types/AdAnalyticsSample';
import {AdBreakSample} from '../types/AdBreakSample';
import {AdSample} from '../types/AdSample';
import {Sample} from '../types/Sample';
import {AnalyticsCall} from '../utils/AnalyticsCall';
import {noOp} from '../utils/Utils';

import {Backend} from './Backend';

export class RemoteBackend implements Backend {
  analyticsCall: AnalyticsCall;
  hasAdModule: boolean;

  constructor(
    hasAdModule: boolean,
    backendBaseUrl: string,
    private licenseKey: string,
  ) {
    this.hasAdModule = hasAdModule;
    this.analyticsCall = new AnalyticsCall(backendBaseUrl);
  }

  sendRequest(sample: Sample) {
    sample.key = this.licenseKey;
    this.analyticsCall.sendRequest(sample, noOp);
  }

  sendUnloadRequest(sample: Sample) {
    sample.key = this.licenseKey;
    this.analyticsCall.sendUnloadRequest(sample);
  }

  sendRequestSynchronous(sample: Sample) {
    sample.key = this.licenseKey;
    this.analyticsCall.sendRequestSynchronous(sample, noOp);
  }

  sendAdRequest(sample: AdAnalyticsSample & AdBreakSample & AdSample) {
    sample.key = this.licenseKey;
    if (!this.hasAdModule) {
      return;
    }
    this.analyticsCall.sendAdRequest(sample);
  }
}
