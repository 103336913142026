import type {AnalyticsConfig} from 'bitmovin-player';

import type {AdapterAPI} from '../../api/AdapterAPI';
import {Analytics} from '../../core/Analytics';
import {Adapter} from '../Adapter';

import {BitmovinPwxInternalAdapter} from './BitmovinPwxInternalAdapter';
import type {AnalyticsContext} from './Types';

export class BitmovinPwxAdapter extends Adapter implements AdapterAPI {
  override internalAdapter: BitmovinPwxInternalAdapter;

  constructor(context: AnalyticsContext, config: AnalyticsConfig) {
    super();
    this.internalAdapter = new BitmovinPwxInternalAdapter(context);
    this.analytics = Analytics.create(config, this.internalAdapter);
  }

  dispose() {
    this.internalAdapter.release();
  }
}
