import type {AudioTrackSwitchedData, Events, default as Hls, Level, SubtitleTrackSwitchData} from 'hls.js';

import {Analytics} from '../../core/Analytics';
import {DownloadSpeedMeter} from '../../core/DownloadSpeedMeter';
import {Event} from '../../enums/Event';
import {MIMETypes} from '../../enums/MIMETypes';
import {Player} from '../../enums/Player';
import {ErrorDetailBackend} from '../../features/errordetails/ErrorDetailBackend';
import {ErrorDetailTracking} from '../../features/errordetails/ErrorDetailTracking';
import {Feature} from '../../features/Feature';
import {FeatureConfig} from '../../features/FeatureConfig';
import {AnalyticsStateMachineOptions} from '../../types/AnalyticsStateMachineOptions';
import {CodecInfo} from '../../types/CodecInfo';
import {DownloadSpeedInfo} from '../../types/DownloadSpeedInfo';
import {FeatureConfigContainer} from '../../types/FeatureConfigContainer';
import {PlaybackInfo} from '../../types/PlaybackInfo';
import {QualityLevelInfo} from '../../types/QualityLevelInfo';
import {SubtitleInfo} from '../../types/SubtitleInfo';
import {isNumber} from '../../utils/Utils';
import {HTML5InternalAdapter} from '../html5/HTML5InternalAdapter';
import {InternalAdapterAPI} from '../internal/InternalAdapterAPI';

import {HlsSpeedMeterAdapter} from './player/HlsSpeedMeterAdapter';

/**
 * Map of Hls Player events that we listen to, each should be type of {@link Events}.
 *
 * Why: This way, we don't need to import string enum {@link Events} from 'hls.js' directly,
 * aka. compile/include it (as value) in our distribution code
 */
export const HLS_PLAYER_EVENT = {
  MEDIA_ATTACHING: 'hlsMediaAttaching' as Events.MEDIA_ATTACHING,
  MEDIA_DETACHING: 'hlsMediaDetaching' as Events.MEDIA_DETACHING,
  MANIFEST_LOADING: 'hlsManifestLoading' as Events.MANIFEST_LOADING,
  AUDIO_TRACK_SWITCHED: 'hlsAudioTrackSwitched' as Events.AUDIO_TRACK_SWITCHED,
  SUBTITLE_TRACK_SWITCH: 'hlsSubtitleTrackSwitch' as Events.SUBTITLE_TRACK_SWITCH,
  DESTROYING: 'hlsDestroying' as Events.DESTROYING,
  ERROR: 'hlsError' as Events.ERROR,
} as const;

export class HlsInternalAdapter extends HTML5InternalAdapter implements InternalAdapterAPI {
  override get downloadSpeedInfo(): DownloadSpeedInfo {
    return this.speedMeter.getInfo();
  }

  private readonly hls: Hls;
  private speedMeter: DownloadSpeedMeter;

  constructor(hls: any, opts?: AnalyticsStateMachineOptions) {
    super(undefined, opts);

    this.resetMedia();

    this.hls = hls;
    this.speedMeter = new HlsSpeedMeterAdapter(hls, new DownloadSpeedMeter()).getDownloadSpeedMeter();
  }

  override initialize(analytics: Analytics): Array<Feature<FeatureConfigContainer, FeatureConfig>> {
    super.initialize(analytics);
    this.registerHlsEvents();
    const errorDetailTracking = new ErrorDetailTracking(
      analytics.errorDetailTrackingSettingsProvider,
      new ErrorDetailBackend(analytics.errorDetailTrackingSettingsProvider.collectorConfig),
      [analytics.errorDetailSubscribable],
      undefined,
    );
    return [errorDetailTracking];
  }

  override clearValues(): void {
    this.speedMeter.reset();
  }

  override getPlayerName = () => Player.HLSJS;

  getPlayerVersion = () => (this.hls as any).constructor.version;

  getCurrentQualityLevelInfo(): null | QualityLevelInfo {
    const currentLevelObj = getHlsCurrentLevel(this.hls);
    if (!currentLevelObj) {
      return null;
    }

    const bitrate = currentLevelObj.bitrate;
    const width = currentLevelObj.width;
    const height = currentLevelObj.height;

    return {
      bitrate: bitrate,
      width: width,
      height: height,
    };
  }

  override isLive = () => {
    const currentLevelObj = getHlsCurrentLevel(this.hls);

    if (currentLevelObj?.details == null) {
      return false;
    }

    return currentLevelObj.details.live;
  };

  override getMIMEType() {
    return MIMETypes.HLS;
  }

  override getStreamURL() {
    return (this.hls as any).url;
  }

  registerHlsEvents() {
    this.hls.on(HLS_PLAYER_EVENT.MEDIA_ATTACHING, this.onMediaAttaching.bind(this));
    this.hls.on(HLS_PLAYER_EVENT.MEDIA_DETACHING, this.onMediaDetaching.bind(this));
    this.hls.on(HLS_PLAYER_EVENT.MANIFEST_LOADING, this.onManifestLoading.bind(this));
    this.hls.on(HLS_PLAYER_EVENT.AUDIO_TRACK_SWITCHED, this.onAudioTrackSwitched.bind(this));
    this.hls.on(HLS_PLAYER_EVENT.SUBTITLE_TRACK_SWITCH, this.onSubtitleLanguageSwitched.bind(this));
    this.hls.on(HLS_PLAYER_EVENT.DESTROYING, this.onDestroying.bind(this));
    this.hls.on(HLS_PLAYER_EVENT.ERROR, this.onHlsError.bind(this));

    // media is already attached, event has been triggered before
    // or we are in the event handler of this event itself.
    // we can not know how the stacktrace to this constructor will look like.
    // therefore we will guard from this case in
    // the onMediaAttaching method (avoid running it twice)
    if (this.hls.media) {
      this.onMediaAttaching();
      this.onManifestLoading();
    }
  }

  onMediaAttaching() {
    // in case we are called again (when we are triggering this ourselves
    // but from the event handler of MEDIA_ATTACHING) we should not run again.
    if (this.mediaElement) {
      return;
    }

    this.mediaElement = this.hls.media as HTMLVideoElement;

    this.registerMediaElement();
    this.onMaybeReady();
  }

  onMediaDetaching() {
    this.unregisterMediaElement();
  }

  onManifestLoading() {
    this.onMaybeReady();
  }

  override getCurrentPlaybackInfo(): PlaybackInfo {
    const selectedSubtitle = this.getSelectedSubtitleLanguage();

    const playbackInfo: PlaybackInfo = {
      ...super.getCurrentPlaybackInfo(),
      audioLanguage: this.getSelectedAudioLanguage(),
      subtitleEnabled: selectedSubtitle != null ? selectedSubtitle.enabled : undefined,
      subtitleLanguage: selectedSubtitle != null ? selectedSubtitle.language : undefined,
    };

    const codecInfo = getHlsCodecInfo(this.hls);
    this.sourceInfoFallbackService.applyAndStoreCodecInfo(playbackInfo, codecInfo);

    return playbackInfo;
  }

  /**
   * errorData: { type : error type, details : error details, fatal : if true, hls.js cannot/will not try to recover, if false, hls.js will try to recover,other error specific data }
   * https://hls-js.netlify.com/api-docs/file/src/events.js.html#lineNumber98
   */
  onHlsError(errorName, errorData) {
    const isFatal = errorData ? errorData.fatal : true;
    if (isFatal) {
      const mediaElement = this.mediaElement;
      let currentTime;
      if (mediaElement != null) {
        currentTime = mediaElement.currentTime;
      }

      const errorMessage = errorData != null ? `${errorData.type}: ${errorData.details}` : undefined;
      this.eventCallback(Event.ERROR, {
        currentTime,
        code: this.getErrorCodeFromHlsErrorType(errorData.type),
        message: errorMessage,
        data: {},
      });
    }
  }

  onAudioTrackSwitched(event: string, data: AudioTrackSwitchedData) {
    const mediaElement = this.mediaElement;
    let currentTime;
    if (mediaElement != null) {
      currentTime = mediaElement.currentTime;
    }

    this.eventCallback(Event.AUDIOTRACK_CHANGED, {
      currentTime,
    });
  }

  onSubtitleLanguageSwitched(event: string, data: SubtitleTrackSwitchData) {
    const mediaElement = this.mediaElement;
    let currentTime;
    if (mediaElement != null) {
      currentTime = mediaElement.currentTime;
    }

    this.eventCallback(Event.SUBTITLE_CHANGE, {
      currentTime,
    });
  }

  private onDestroying = () => {
    this.eventCallback(Event.SOURCE_UNLOADED, {});
    this.release();
  };

  /**
   * returns mapped error code for Hlsjs ErrorTypes
   * @param type one of the ErrorTypes according to https://github.com/video-dev/hls.js/blob/v1.5.1/src/errors.ts#L1
   */
  private getErrorCodeFromHlsErrorType(type: string): number {
    switch (type) {
      case 'networkError':
        return 2;
      case 'mediaError':
        return 3;
      case 'keySystemError':
        return 4;
      case 'muxError':
        return 5;
      // default equals `otherError`
      default:
        return 1;
    }
  }

  private getSelectedAudioLanguage(): string | undefined {
    if (this.hls.audioTrack == null || this.hls.audioTrack < 0) {
      return undefined;
    }
    return (this.hls.audioTracks[this.hls.audioTrack] as any).lang;
  }

  private getSelectedSubtitleLanguage(): SubtitleInfo | undefined {
    // Check if function is supported (in latest hls version), otherwise use fallback option
    if (this.hls.subtitleDisplay != null) {
      const isSubtitleDisplayed = this.hls.subtitleTrack >= 0 && this.hls.subtitleDisplay === true;
      return {
        enabled: isSubtitleDisplayed,
        language: isSubtitleDisplayed ? this.hls.subtitleTracks[this.hls.subtitleTrack].lang : undefined,
      };
    } else {
      const {subtitleTrackController} = this.hls as any;
      if (subtitleTrackController != null && subtitleTrackController.media != null) {
        return this.getSelectedSubtitleFromMediaElement(subtitleTrackController.media);
      }
    }
    return undefined;
  }
}

export const getHlsCodecInfo = (hls: Hls): CodecInfo | undefined => {
  const currentLevelObj = getHlsCurrentLevel(hls);
  if (!currentLevelObj) {
    return undefined;
  }

  return {
    videoCodec: currentLevelObj.videoCodec,
    audioCodec: currentLevelObj.audioCodec,
  };
};

export const getHlsCurrentLevel = (hls: Hls): Level | undefined => {
  if (hls.levels == null || hls.levels.length === 0) {
    return undefined;
  }

  if (!isNumber(hls.currentLevel) || hls.currentLevel < 0) {
    return undefined;
  }

  return hls.levels[hls.currentLevel];
};
