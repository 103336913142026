import {AdAnalyticsSample} from '../types/AdAnalyticsSample';
import {AdBreakSample} from '../types/AdBreakSample';
import {AdSample} from '../types/AdSample';
import {Sample} from '../types/Sample';

import {Backend} from './Backend';

export class NoOpBackend implements Backend {
  sendRequest(sample: Sample) {
    // empty
  }
  sendUnloadRequest(sample: Sample) {
    // empty
  }
  sendRequestSynchronous(sample: Sample) {
    // empty
  }
  sendAdRequest(sample: AdAnalyticsSample & AdBreakSample & AdSample) {
    // empty
  }
}
