export interface AnalyticsDebugConfig {
  fields?: string[];
}
export interface CollectorConfig {
  backendUrl?: string;
  enabled?: boolean;
  cookiesEnabled?: boolean;
  cookiesDomain?: string;
  cookiesMaxAge?: number;
  origin?: string;
}

export interface AnalyticsConfig {
  // Analytics/Collector properties
  config?: CollectorConfig;
  debug?: AnalyticsDebugConfig | boolean;
  key?: string;
  playerKey?: string;

  // Device specific properties
  deviceType?: string;
  deviceClass?: 'Console' | 'Desktop' | 'Other' | 'Phone' | 'STB' | 'Tablet' | 'TV' | 'Wearable';
  player?: string;

  // Default Metadata
  userId?: string; // deprecated
  customUserId?: string;

  // Source specific properties
  cdnProvider?: string;
  videoId?: string;
  title?: string;
  isLive?: boolean;
  customData1?: any;
  customData2?: any;
  customData3?: any;
  customData4?: any;
  customData5?: any;
  customData6?: any;
  customData7?: any;
  customData8?: any;
  customData9?: any;
  customData10?: any;
  customData11?: any;
  customData12?: any;
  customData13?: any;
  customData14?: any;
  customData15?: any;
  customData16?: any;
  customData17?: any;
  customData18?: any;
  customData19?: any;
  customData20?: any;
  customData21?: any;
  customData22?: any;
  customData23?: any;
  customData24?: any;
  customData25?: any;
  customData26?: any;
  customData27?: any;
  customData28?: any;
  customData29?: any;
  customData30?: any;
  experimentName?: string;
}

export function removeSourceRelatedFields(config: AnalyticsConfig): AnalyticsConfig {
  const sourceSpecificProperties = ['cdnProvider', 'videoId', 'title', 'isLive', 'experimentName'];
  const customDataFieldsRegex = /customData\d{1,2}$/g;

  for (const key in config) {
    if (key.match(customDataFieldsRegex) != null || sourceSpecificProperties.includes(key)) {
      delete config[key];
    }
  }

  return config;
}
