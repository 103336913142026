import type {PlayerAPI, PlayerConfig} from 'bitmovin-player';

import type {AdapterAPI, SsaiAdBreakMetadata, SsaiAdMetadata, SsaiApi} from '../../api/AdapterAPI';
import {Analytics} from '../../core/Analytics';
import {AnalyticsConfig} from '../../types/AnalyticsConfig';
import {AnalyticsStateMachineOptions} from '../../types/AnalyticsStateMachineOptions';
import {logger} from '../../utils/Logger';
import {Adapter} from '../Adapter';

import {Bitmovin8InternalAdapter} from './Bitmovin8InternalAdapter';
import {SsaiService} from './playback/SsaiService';

export class Bitmovin8Adapter extends Adapter implements AdapterAPI {
  constructor(player: any, opts?: AnalyticsStateMachineOptions) {
    super();

    if (this.hasPlayerAlreadyBeenAugmented(player)) {
      logger.errorMessageToUser('Bitmovin Analytics is already hooked up to this player instance');
      return;
    }
    this.markPlayerInstanceAsAugmented(player);

    const playerConfig: PlayerConfig = (player as PlayerAPI).getConfig();
    let analyticsConfig: AnalyticsConfig = (playerConfig as any).analytics;
    if (analyticsConfig === undefined) {
      analyticsConfig = {};
    }

    analyticsConfig.playerKey = analyticsConfig.playerKey || playerConfig.key;

    const ssaiService = new SsaiService(player);
    this.internalAdapter = Bitmovin8InternalAdapter.create(player, ssaiService, opts);
    this.analytics = Analytics.create(analyticsConfig, this.internalAdapter, ssaiService);

    // We do this here in order to put the reference of the adapter onto the player
    // customer using the bitmovin adapter standalone might not know that they need to
    // hold a reference
    player.analytics = this;
    this.wrapPlayerLoad(player, this.analytics);
  }

  ssai: SsaiApi = {
    adBreakStart: (adBreakMetadata?: SsaiAdBreakMetadata) => {
      (this.internalAdapter as Bitmovin8InternalAdapter).adBreakStart(adBreakMetadata);
    },
    adStart: (adMetadata?: SsaiAdMetadata) => {
      (this.internalAdapter as Bitmovin8InternalAdapter).adStart(adMetadata);
      // we need to call this here to revert the customData values to the values before the adBreak
      // this is mainly needed to have clear transitions between ads, and we don't
      // copy over customData values from the first ad into the second ad
      // the adMetadata is applied after this call in the manipulate method when a sample is sent
      // it is a bit of a hack, but it is necessary right now due to the way how samples are created
      this.analytics.setConfigParameters();
    },
    adBreakEnd: () => {
      (this.internalAdapter as Bitmovin8InternalAdapter).adBreakEnd();
      // we need to call this here to revert the customData values to the values we had before the ad
      this.analytics.setConfigParameters();
    },
  };

  /**
   * intercept `player.load` with automated sourceChange handling
   */
  private wrapPlayerLoad(player: PlayerAPI, analytics: Analytics) {
    const originalLoad = player.load;
    player.load = (...args: Parameters<typeof player.load>): Promise<void> => {
      if (args.length > 0) {
        const analyticsConfig = (args[0] as any).analytics;
        // we reset the analytics and reload with a new config
        analytics.sourceChange(analyticsConfig);
      }

      return originalLoad.apply(player, args);
    };
  }
}
