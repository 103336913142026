import type {StateMachineErrorCallback, StateMachineEventDef} from 'javascript-state-machine';

import {HeartbeatPayload} from '../types/StateMachineCallbacks';
import {logger} from '../utils/Logger';

type OnReturn = {
  stayIn: (state: string) => StateMachineEventDef;
};

export function on(event: string): OnReturn {
  return {
    stayIn: (state: string) => ({name: event, from: state, to: state}),
  };
}

/**
 * By default, if you try to call an event method that is not allowed in the current state,
 * the state machine will throw an exception.
 * To handle this problem ourselves, we define a custom error handler that will log the error.
 *
 * @see https://github.com/jakesgordon/javascript-state-machine/blob/2.4.0/README.md#handling-failures
 */
export const customStateMachineErrorCallback: StateMachineErrorCallback = (
  eventName,
  from,
  to,
  args,
  errorCode,
  errorMessage,
  _ex,
) => {
  let msg = `StateMachine error callback: ${errorMessage}`;
  msg += `, eventName: ${eventName}, from: ${from}, to: ${to}`;
  if (args != null) {
    msg += `, args: ${JSON.stringify(args)}`;
  }
  msg += `, errorCode: ${errorCode}`;

  logger.warn(msg);
};

/**
 *
 * @param duration duration of the heartbeat
 * @param state state of heartbeat
 * @returns payload containing the
 */
export const createHeartbeatPayload = (duration: number, state: Lowercase<string>): HeartbeatPayload => {
  let payload: HeartbeatPayload = {};
  switch (state) {
    case 'playing':
      payload = {played: duration};
      break;
    case 'buffering':
      payload = {buffered: duration};
      break;
    case 'paused':
      payload = {paused: duration};
      break;
    default:
      break;
  }
  return payload;
};

/**
 * For unknown reason, we want to report states that does not map to callback function names
 * in javascript-state-machine `onleavestate` callback
 *
 * This does not apply to Amazon IVS state machine, that was written in different code style and does not include such `onleavestate` callback logic
 *
 */
export const logMissingCallbackWarning = (state: string | undefined, ignoreStates: string[] = []) => {
  if (state == null) {
    return;
  }

  const shouldReportMissingCallback = ignoreStates.find((ignoredState) => state === ignoredState) == null;
  if (shouldReportMissingCallback) {
    logger.warn('Could not find callback function for ' + state);
  }
};
