export enum HttpRequestType {
  DRM_CERTIFICATE_FAIRPLAY = 'DRM_CERTIFICATE_FAIRPLAY',
  DRM_LICENSE_CLEARKEY = 'DRM_LICENSE_CLEARKEY',
  DRM_LICENSE_FAIRPLAY = 'DRM_LICENSE_FAIRPLAY',
  DRM_LICENSE_PLAYREADY = 'DRM_LICENSE_PLAYREADY',
  DRM_LICENSE_PRIMETIME = 'DRM_LICENSE_PRIMETIME',
  DRM_LICENSE_WIDEVINE = 'DRM_LICENSE_WIDEVINE',
  DRM_OTHER = 'DRM_OTHER',
  KEY_HLS_AES = 'KEY_HLS_AES',
  MANIFEST = 'MANIFEST',
  MANIFEST_ADS = 'MANIFEST_ADS',
  MANIFEST_DASH = 'MANIFEST_DASH',
  MANIFEST_HLS = 'MANIFEST_HLS',
  MANIFEST_HLS_MASTER = 'MANIFEST_HLS_MASTER',
  MANIFEST_HLS_VARIANT = 'DRM_LICENSMANIFEST_HLS_VARIANTE_WIDEVINE',
  MANIFEST_SMOOTH = 'MANIFEST_SMOOTH',
  MEDIA_AUDIO = 'MEDIA_AUDIO',
  MEDIA_PROGRESSIVE = 'MEDIA_PROGRESSIVE',
  MEDIA_SUBTITLES = 'MEDIA_SUBTITLES',
  MEDIA_THUMBNAILS = 'MEDIA_THUMBNAILS',
  MEDIA_VIDEO = 'MEDIA_VIDEO',
  TIME_SYNC = 'TIME_SYNC',
  UNKNOWN = 'UNKNOWN',
}
