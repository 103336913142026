import {
  AnalyticsEventBase,
  AudioChangeEvent,
  ErrorEvent,
  SourceChangeEvent,
  VideoChangeEvent,
} from '../types/EventData';

export enum Event {
  AUDIO_CHANGE = 'audioChange',
  AUDIOTRACK_CHANGED = 'audioTrackChanged',
  CUSTOM_DATA_CHANGE = 'customDataChange',
  END = 'end',
  END_AD = 'adEnd',
  END_BUFFERING = 'endBuffering',
  END_CAST = 'endCasting',
  END_FULLSCREEN = 'endFullscreen',
  ERROR = 'playerError',
  MANUAL_SOURCE_CHANGE = 'manualSourceChangeInitiated',
  MUTE = 'mute',
  PAUSE = 'pause',
  PLAY = 'play',
  PLAYING = 'playing',
  PLAYLIST_TRANSITION = 'playlistTransition',
  READY = 'ready',
  SCREEN_RESIZE = 'resize',
  SEEK = 'seek',
  SEEKED = 'seeked',
  SETUP = 'setup',
  SOURCE_LOADED = 'sourceLoaded',
  SOURCE_UNLOADED = 'sourceUnloaded',
  START_AD = 'adStart',
  START_BUFFERING = 'startBuffering',
  START_CAST = 'startCasting',
  START_FULLSCREEN = 'startFullscreen',
  SUBTITLE_CHANGE = 'subtitleChange',
  TIMECHANGED = 'timechanged',
  UN_MUTE = 'unMute',
  UNLOAD = 'unload',
  VIDEO_CHANGE = 'videoChange',
  VIDEOSTART_TIMEOUT = 'VIDEOSTART_TIMEOUT',
}

export interface EventMap {
  [Event.SETUP]: AnalyticsEventBase;
  [Event.READY]: AnalyticsEventBase;
  [Event.SOURCE_LOADED]: AnalyticsEventBase;
  [Event.PLAY]: AnalyticsEventBase;
  [Event.PLAYING]: AnalyticsEventBase;
  [Event.PAUSE]: AnalyticsEventBase;
  [Event.TIMECHANGED]: AnalyticsEventBase;
  [Event.SEEK]: AnalyticsEventBase;
  [Event.SEEKED]: AnalyticsEventBase;
  [Event.START_CAST]: AnalyticsEventBase;
  [Event.END_CAST]: AnalyticsEventBase;
  [Event.START_BUFFERING]: AnalyticsEventBase;
  [Event.END_BUFFERING]: AnalyticsEventBase;
  [Event.AUDIOTRACK_CHANGED]: AnalyticsEventBase;
  [Event.AUDIO_CHANGE]: AudioChangeEvent;
  [Event.VIDEO_CHANGE]: VideoChangeEvent;
  [Event.START_FULLSCREEN]: AnalyticsEventBase;
  [Event.END_FULLSCREEN]: AnalyticsEventBase;
  [Event.START_AD]: AnalyticsEventBase;
  [Event.SUBTITLE_CHANGE]: AnalyticsEventBase;
  [Event.END_AD]: AnalyticsEventBase;
  [Event.MUTE]: AnalyticsEventBase;
  [Event.UN_MUTE]: AnalyticsEventBase;
  [Event.ERROR]: ErrorEvent;
  [Event.SCREEN_RESIZE]: AnalyticsEventBase;
  [Event.UNLOAD]: AnalyticsEventBase;
  [Event.END]: AnalyticsEventBase;
  [Event.SOURCE_UNLOADED]: AnalyticsEventBase;
  [Event.MANUAL_SOURCE_CHANGE]: SourceChangeEvent;
  [Event.VIDEOSTART_TIMEOUT]: AnalyticsEventBase;
  [Event.CUSTOM_DATA_CHANGE]: AnalyticsEventBase;
  [Event.PLAYLIST_TRANSITION]: AnalyticsEventBase;
}
